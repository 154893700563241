.hero-animation {
    animation: 2s heroAnimate;
    bottom: 0;
    top: 0
}

.hero-text-animation {
    animation: 1.5s heroTextAnimate .25s
}


@keyframes heroAnimate {
    0% {
        bottom: 0;
        top: 100%
    }

    50% {
        bottom: -100%;
        top: -10%
    }

    to {
        bottom: 0;
        top: 0
    }
}

@keyframes heroTextAnimate {
    0% {
        letter-spacing: .05em
    }

    50% {
        letter-spacing: 25px
    }

    to {
        letter-spacing: .05em
    }
}