.glow-on-hover {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0
}

.glow-on-hover:before {
    content: "";
    background: linear-gradient(45deg,hsl(0,100%,15%),hsl(27,100%,15%),hsl(59,100%,15%),hsl(103,100%,15%),hsl(170,100%,15%),hsl(230,100%,15%),hsl(269,100%,15%),hsl(313,100%,15%),hsl(0,100%,15%));
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 4px
}

.glow-on-hover:hover:before {
    opacity: 1
}

.glow-on-hover:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px
}

@keyframes glowing {
    0% {
        background-position: 0 0
    }

    50% {
        background-position: 400% 0
    }

    to {
        background-position: 0 0
    }
}